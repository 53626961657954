import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import CNALogo from '../images/CNALogo.png'
import CIBCLogo from '../images/CIBC.png'
import ELVNLogo from '../images/ELVN_Logo.jpg'
import LevisLogo from '../images/LevisLogo.png'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ textAlign: 'center' }}>
      <div style={{ maxWidth: `960px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <p>After years promoting LGBTQ contributions to world history and culture, the Legacy Walk Rainbow Pylon
      Streetscape was officially designated the largest LGBTQ Historic Landmark on earth! A few short weeks later,
      LGBTQ-Inclusive Curriculum Bill (HB246), co-written by the Legacy Project, became Illinois law! These two groundbreaking
      achievements have catapulted the Legacy Project – a small, volunteer-driven charity – into the pantheon of LGBTQ History itself!</p>
      <h3><em>IT’S TIME TO CELEBRATE!!</em></h3>
      <h2>LANDMARK: The Legacy Project [Un]Gala</h2>
      <p>October 10, 2019 | 5:30 –8:30</p>
      <h2>CHEZ EVENT SPACE</h2>
      <p>
        247 E. Ontario Street, Chicago, IL<br />
        <span style={{ fontWeight: 'bold' }}>OPEN BAR | HEAVY HORS D’OEUVRES | MUSIC</span><br />
        Event Emcee: Gwen La Roka<br />
        Music by DJ All The Way Kay
      </p>
      <p>
        <h3 style={{ color: '#FE2D2D' }}>This event is sold out!</h3>
      </p>
      <p><em>Be on hand when we honor the contributions of four powerhouse activists whose work from Day One has ensured the success of the Legacy Project:</em></p>
      <ul style={{ listStyleType: 'none', fontWeight: 'bold' }}>
        <li>LORI F. CANNON – Legacy Advocate Award</li>
        <li>PAUL L. HIGHFIELD – Legacy Philanthropist Award</li>
        <li>OWEN J. KEEHNEN – Legacy Educator Award</li>
        <li>CARRIE E. MAXWELL – Legacy Volunteer Award</li>
      </ul>
      <p><Link to="/honorees/" style={{ color: '#000051', textDecoration: 'none' }}>Learn more about the honorees &#x2192;</Link></p>
      <p><em>These four individuals’ selfless commitment embodies the work of the Legacy Project to illuminate and affirm the lives
      of Lesbian, Gay, Bisexual, Transgender, and Queer people; to honor LGBTQ experiences and accomplishments; to collect and preserve
      their contributions to world history and culture; to educate and inspire the public and young people; and to assure an inclusive
      and equitable future.</em></p>
      <h2>Sponsored By</h2>
      <h4>PLATINUM LEVEL</h4>
      <p>
        <img src={LevisLogo} alt="Levi Strauss & Co." style={{ width: '200px' }}/>
      </p>
      <p>
        <img src={CIBCLogo} alt="CIBC" style={{ width: '200px' }} />
      </p>
      <h4>GOLD LEVEL</h4>
      <p>
        <img src={CNALogo} alt="CNA" style={{ width: '200px' }} />
      </p>
      <p>
        <img src={ELVNLogo} alt="ELVN" style={{ width: '200px' }} />
      </p>
      <h4>SILVER LEVEL</h4>
      <p>Barilla North America</p>
      <p>Breakthru Beverage Group</p>
      <p>Michael Burke and Robert Charles</p>
      <p>Chez Event Space</p>
      <p>Chicago Cubs</p>
      <p>J&L Catering</p>
      <p>LGBT Chamber of Commerce of Illinois</p>
      <p>Luxe Productions</p>
      <p>MillerCoors</p>
      <p>Rush Hospital</p>
      <p>Stoli Group USA</p>
      <p>Vivify Beverage</p>
    </div>
  </Layout>
)

export default IndexPage
